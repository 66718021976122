<template>
  <div class="card p-shadow-6">
    <h1>Veículos</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <VehicleForm
      :show="showVehicleModel"
      v-on:cancel="showVehicleModel = false"
      v-on:vehicleCreated="vehicleCreatedOk"
    />
    <div style="height: 65vh">
      <DataTable
        :value="vehicles"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="category.name"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem Veículos. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-plus"
              @click="showVehicleModel = true"
              v-if="$store.state.auth.user.isEsa || can('addFleetVehicles')"
            />
          </div>
        </template>
        <Column
          field="registration"
          filterField="registration"
          header="Matrícula"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span
              v-if="
                $store.state.auth.user.isEsa || can('seeFleetVehicleDetails')
              "
              class="clickable-item"
              @click="$router.push(`/vehicle-details/${slotProps.data.id}`)"
              >{{ slotProps.data.registration }}</span
            >
            <span v-else>{{ slotProps.data.registration }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="brand"
          filterField="brand"
          header="Marca"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="model"
          filterField="model"
          header="Modelo"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="office"
          filterField="office"
          header="Escritório"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="offices"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="current_condutor"
          filterField="current_condutor.conductor.username"
          header="Condutor"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            {{
              slotProps.data.current_condutor
                ? slotProps.data.current_condutor.conductor.username +
                  " | " +
                  slotProps.data.current_condutor.start
                : ""
            }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="current_insurance"
          filterField="current_insurance.end"
          header="Seguro"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            {{
              slotProps.data.current_insurance
                ? slotProps.data.current_insurance.insurer +
                  " | " +
                  slotProps.data.current_insurance.end
                : ""
            }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="registration_date"
          filterField="registration_date"
          header="Data Mat."
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="active"
          header="Activo"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            {{ slotProps.data.active == 1 ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import "vue-loading-overlay/dist/vue-loading.css";
import fleetService from "../services/fleet.service";
import VehicleForm from "../components/fleet/VehicleForm.vue";
export default {
  name: "Vehicles",
  components: { VehicleForm },
  data() {
    return {
      loading: true,
      vehicles: [],
      offices: [],
      filters: {
        registration: { value: null, matchMode: FilterMatchMode.CONTAINS },
        registration_date: { value: null, matchMode: FilterMatchMode.CONTAINS },
        brand: { value: null, matchMode: FilterMatchMode.CONTAINS },
        model: { value: null, matchMode: FilterMatchMode.CONTAINS },
        office: { value: null, matchMode: FilterMatchMode.EQUALS },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
        "current_condutor.conductor.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "current_insurance.end": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      showVehicleModel: false,
    };
  },
  async created() {
    this.offices = await fleetService.getOffices();
    this.vehicles = await fleetService.getVehicles();
    this.loading = false;
  },
  methods: {
    vehicleCreatedOk(vehicle) {
      this.vehicles.push(vehicle);
      return (this.showVehicleModel = false);
    },
  },
};
</script>
