var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Toast',{style:({ width: _vm.$isMobile() ? '85vw' : '' })}),_c('Dialog',{style:({ width: _vm.$isMobile() ? '100vw' : '50vw' }),attrs:{"visible":_vm.show,"header":'Adicionar Veículo',"modal":true,"closable":false},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-success",attrs:{"label":'Guardar',"icon":"pi pi-check"},on:{"click":_vm.save}}),_c('Button',{staticClass:"p-button-danger",attrs:{"label":"Cancelar","icon":"pi pi-times"},on:{"click":_vm.cancel}})]},proxy:true}])},[_c('form',{staticClass:"p-col-12",staticStyle:{"min-height":"60vh"},attrs:{"name":"VehicleForm"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"p-fluid p-formgrid p-grid",staticStyle:{"min-height":"20vh"}},[_c('div',{staticClass:"p-field p-col-12 p-md-3"},[_c('span',{staticClass:"p-float-label"},[_c('InputMask',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"p-column-filter",class:[
                {
                  'p-invalid': _vm.errors.has('registration'),
                },
                'form-control',
              ],attrs:{"mask":"**-**-**","name":'registration',"id":'registration'},model:{value:(_vm.vehicleModel.registration),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "registration", $$v)},expression:"vehicleModel.registration"}}),_c('label',{attrs:{"for":"registration"}},[_vm._v("Matrícula")])],1),(_vm.errors.has('registration'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Matrícula é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3"},[_c('div',{staticClass:"p-inputgroup"},[_c('span',{staticClass:"p-inputgroup-addon"},[_c('i',{staticClass:"pi pi-calendar"})]),_c('span',{staticClass:"p-float-label"},[_c('v-date-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":'registration_date_picker',"id":'registration_date_picker',"max-date":new Date(),"masks":{
                  input: 'YYYY-MM-DD',
                }},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('span',{staticClass:"p-float-label"},[_c('input',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                        {
                          'p-error': _vm.errors.has('registration_date'),
                        },
                        'form-control',
                        'p-inputtext',
                        'p-component',
                        'p-filled',
                      ],attrs:{"name":'registration_date',"id":'registration_date'},domProps:{"value":inputValue}},inputEvents)),_c('label',{attrs:{"for":"registration_date"}},[_vm._v("Data da Matrícula")])])]}}]),model:{value:(_vm.vehicleModel.registration_date),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "registration_date", $$v)},expression:"vehicleModel.registration_date"}})],1)]),(_vm.errors.has('registration_date'))?_c('small',{staticClass:"p-error p-ml-5",attrs:{"role":"alert"}},[_vm._v(" Data da Matrícula é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3"},[_c('div',{staticClass:"p-inputgroup"},[_c('span',{staticClass:"p-inputgroup-addon"},[_c('i',{staticClass:"pi pi-calendar"})]),_c('span',{staticClass:"p-float-label"},[_c('v-date-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":'acquisition_date_picker',"id":'acquisition_date_picker',"max-date":new Date(),"masks":{
                  input: 'YYYY-MM-DD',
                }},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('span',{staticClass:"p-float-label"},[_c('input',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                        {
                          'p-error': _vm.errors.has('acquisition_date'),
                        },
                        'form-control',
                        'p-inputtext',
                        'p-component',
                        'p-filled',
                      ],attrs:{"name":'acquisition_date',"id":'acquisition_date'},domProps:{"value":inputValue}},inputEvents)),_c('label',{attrs:{"for":"acquisition_date"}},[_vm._v("Data de Aquisição")])])]}}]),model:{value:(_vm.vehicleModel.acquisition_date),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "acquisition_date", $$v)},expression:"vehicleModel.acquisition_date"}})],1)]),(_vm.errors.has('acquisition_date'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Data de Aquisição é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3"},[_c('span',{staticClass:"p-float-label"},[_c('Dropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                {
                  'p-invalid': _vm.errors.has('acquisition_type'),
                },
                'form-control',
              ],attrs:{"options":_vm.acquisitionTypesList,"name":'acquisition_type',"id":'acquisition_type'},model:{value:(_vm.vehicleModel.acquisition_type),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "acquisition_type", $$v)},expression:"vehicleModel.acquisition_type"}}),_c('label',{attrs:{"for":"acquisition_type"}},[_vm._v("Tipo de Aquisição")])],1),(_vm.errors.has('acquisition_type'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Tipo de Aquisição é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('AutoComplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                {
                  'p-invalid': _vm.errors.has('brand'),
                },
                'form-control',
              ],attrs:{"suggestions":_vm.filteredBrands,"id":'brand',"name":'brand'},on:{"complete":function($event){return _vm.searchBrand($event)}},model:{value:(_vm.vehicleModel.brand),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "brand", $$v)},expression:"vehicleModel.brand"}}),_c('label',{attrs:{"for":"brand"}},[_vm._v("Marca")])],1),(_vm.errors.has('brand'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Marca é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('AutoComplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                {
                  'p-invalid': _vm.errors.has('model'),
                },
                'form-control',
              ],attrs:{"suggestions":_vm.filteredModels,"id":'model',"name":'model'},on:{"complete":function($event){return _vm.searchModels($event)}},model:{value:(_vm.vehicleModel.model),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "model", $$v)},expression:"vehicleModel.model"}}),_c('label',{attrs:{"for":"model"}},[_vm._v("Modelo")])],1),(_vm.errors.has('model'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Modelo é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('AutoComplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                {
                  'p-invalid': _vm.errors.has('color'),
                },
                'form-control',
              ],attrs:{"suggestions":_vm.filteredColors,"id":'color',"name":'color'},on:{"complete":function($event){return _vm.searchColors($event)}},model:{value:(_vm.vehicleModel.color),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "color", $$v)},expression:"vehicleModel.color"}}),_c('label',{attrs:{"for":"color"}},[_vm._v("Cor")])],1),(_vm.errors.has('color'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Cor é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('Dropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                {
                  'p-invalid': _vm.errors.has('office'),
                },
                'form-control',
              ],attrs:{"options":_vm.offices,"name":'office',"id":'office'},model:{value:(_vm.vehicleModel.office),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "office", $$v)},expression:"vehicleModel.office"}}),_c('label',{attrs:{"for":"office"}},[_vm._v("Escritório")])],1),(_vm.errors.has('office'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Escritório é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('Dropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                {
                  'p-invalid': _vm.errors.has('fuel'),
                },
                'form-control',
              ],attrs:{"options":_vm.fuelList,"name":'fuel',"id":'fuel'},model:{value:(_vm.vehicleModel.fuel),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "fuel", $$v)},expression:"vehicleModel.fuel"}}),_c('label',{attrs:{"for":"fuel"}},[_vm._v("Combustivel")])],1),(_vm.errors.has('fuel'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Combustivel é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('InputText',{class:[
                {
                  'p-invalid': _vm.errors.has('green_way'),
                },
                'form-control',
              ],attrs:{"type":"text","id":'green_way',"name":'green_way'},model:{value:(_vm.vehicleModel.green_way),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "green_way", $$v)},expression:"vehicleModel.green_way"}}),_c('label',{attrs:{"for":"green_way"}},[_vm._v("Nº Via Verde")])],1)]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('InputNumber',{class:[
                {
                  'p-invalid': _vm.errors.has('kms'),
                },
                'form-control',
              ],attrs:{"id":'kms',"name":'kms'},model:{value:(_vm.vehicleModel.kms),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "kms", $$v)},expression:"vehicleModel.kms"}}),_c('label',{attrs:{"for":"kms"}},[_vm._v("Km's")])],1)]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('InputNumber',{class:[
                {
                  'p-invalid': _vm.errors.has('km_next_revision'),
                },
                'form-control',
              ],attrs:{"id":'km_next_revision',"name":'km_next_revision'},model:{value:(_vm.vehicleModel.km_next_revision),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "km_next_revision", $$v)},expression:"vehicleModel.km_next_revision"}}),_c('label',{attrs:{"for":"km_next_revision"}},[_vm._v("Próxima Revisão (Km's)")])],1)]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-3"},[_c('div',{staticClass:"p-inputgroup"},[_c('span',{staticClass:"p-inputgroup-addon"},[_c('i',{staticClass:"pi pi-calendar"})]),_c('span',{staticClass:"p-float-label"},[_c('v-date-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":'inspection_date_picker',"id":'inspection_date_picker',"masks":{
                  input: 'YYYY-MM-DD',
                }},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('span',{staticClass:"p-float-label"},[_c('input',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                        {
                          'p-error': _vm.errors.has('inspection_date'),
                        },
                        'form-control',
                        'p-inputtext',
                        'p-component',
                        'p-filled',
                      ],attrs:{"name":'inspection_date',"id":'inspection_date'},domProps:{"value":inputValue}},inputEvents)),_c('label',{attrs:{"for":"inspection_date"}},[_vm._v("Data de Inspeção")])])]}}]),model:{value:(_vm.vehicleModel.inspection_date),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "inspection_date", $$v)},expression:"vehicleModel.inspection_date"}})],1)]),(_vm.errors.has('inspection_date'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Data de Inspeção é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-3"},[_c('span',{staticClass:"p-float-label"},[_c('InputText',{class:[
                {
                  'p-invalid': _vm.errors.has('vin'),
                },
                'form-control',
              ],attrs:{"type":"text","id":'vin',"name":'vin'},model:{value:(_vm.vehicleModel.vin),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "vin", $$v)},expression:"vehicleModel.vin"}}),_c('label',{attrs:{"for":"vin"}},[_vm._v("VIN")])],1)]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-3"},[_c('span',{staticClass:"p-float-label"},[_c('InputText',{class:[
                {
                  'p-invalid': _vm.errors.has('fleet_card_number'),
                },
                'form-control',
              ],attrs:{"type":"text","id":'fleet_card_number',"name":'fleet_card_number'},model:{value:(_vm.vehicleModel.fleet_card_number),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "fleet_card_number", $$v)},expression:"vehicleModel.fleet_card_number"}}),_c('label',{attrs:{"for":"fleet_card_number"}},[_vm._v("Nº Cartão Frota")])],1)]),_c('div',{staticClass:"p-field p-col-12 p-md-3 p-mt-3"},[_c('span',{staticClass:"p-float-label"},[_c('AutoComplete',{class:[
                {
                  'p-invalid': _vm.errors.has('fleet_card_entity'),
                },
                'form-control',
              ],attrs:{"suggestions":_vm.filteredEntity,"id":'fleet_card_entity',"name":'fleet_card_entity'},on:{"complete":function($event){return _vm.searchEntity($event)}},model:{value:(_vm.vehicleModel.fleet_card_entity),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "fleet_card_entity", $$v)},expression:"vehicleModel.fleet_card_entity"}}),_c('label',{attrs:{"for":"fleet_card_entity"}},[_vm._v("Entidade Cartão Frota")])],1)]),_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('Textarea',{class:['form-control'],attrs:{"autoResize":true,"rows":"5","id":'comments',"name":'comments'},model:{value:(_vm.vehicleModel.comments),callback:function ($$v) {_vm.$set(_vm.vehicleModel, "comments", $$v)},expression:"vehicleModel.comments"}}),_vm._v(" "),_c('label',{attrs:{"for":"comments"}},[_vm._v("Comentários")])],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }