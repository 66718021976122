<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="'Adicionar Veículo'"
      :modal="true"
      :closable="false"
    >
      <form
        name="VehicleForm"
        class="p-col-12"
        @submit.prevent="save"
        style="min-height: 60vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <InputMask
                v-model="vehicleModel.registration"
                mask="**-**-**"
                class="p-column-filter"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('registration'),
                  },
                  'form-control',
                ]"
                :name="'registration'"
                :id="'registration'"
              />
              <label for="registration">Matrícula</label>
            </span>
            <small
              v-if="errors.has('registration')"
              class="p-error"
              role="alert"
            >
              Matrícula é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-3">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  v-model="vehicleModel.registration_date"
                  :name="'registration_date_picker'"
                  :id="'registration_date_picker'"
                  v-validate="'required'"
                  :max-date="new Date()"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'registration_date'"
                        :id="'registration_date'"
                        v-bind:class="[
                          {
                            'p-error': errors.has('registration_date'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        v-validate="'required'"
                      />
                      <label for="registration_date">Data da Matrícula</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small
              v-if="errors.has('registration_date')"
              class="p-error p-ml-5"
              role="alert"
            >
              Data da Matrícula é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :name="'acquisition_date_picker'"
                  :id="'acquisition_date_picker'"
                  v-model="vehicleModel.acquisition_date"
                  v-validate="'required'"
                  :max-date="new Date()"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'acquisition_date'"
                        :id="'acquisition_date'"
                        v-bind:class="[
                          {
                            'p-error': errors.has('acquisition_date'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        v-validate="'required'"
                      />
                      <label for="acquisition_date">Data de Aquisição</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small
              v-if="errors.has('acquisition_date')"
              class="p-error"
              role="alert"
            >
              Data de Aquisição é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <Dropdown
                v-model="vehicleModel.acquisition_type"
                :options="acquisitionTypesList"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('acquisition_type'),
                  },
                  'form-control',
                ]"
                :name="'acquisition_type'"
                :id="'acquisition_type'"
              />
              <label for="acquisition_type">Tipo de Aquisição</label>
            </span>
            <small
              v-if="errors.has('acquisition_type')"
              class="p-error"
              role="alert"
            >
              Tipo de Aquisição é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <AutoComplete
                :suggestions="filteredBrands"
                @complete="searchBrand($event)"
                v-model="vehicleModel.brand"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('brand'),
                  },
                  'form-control',
                ]"
                :id="'brand'"
                :name="'brand'"
              />
              <label for="brand">Marca</label>
            </span>
            <small v-if="errors.has('brand')" class="p-error" role="alert">
              Marca é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <AutoComplete
                :suggestions="filteredModels"
                @complete="searchModels($event)"
                v-model="vehicleModel.model"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('model'),
                  },
                  'form-control',
                ]"
                :id="'model'"
                :name="'model'"
              />
              <label for="model">Modelo</label>
            </span>
            <small v-if="errors.has('model')" class="p-error" role="alert">
              Modelo é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <AutoComplete
                :suggestions="filteredColors"
                @complete="searchColors($event)"
                v-model="vehicleModel.color"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('color'),
                  },
                  'form-control',
                ]"
                :id="'color'"
                :name="'color'"
              />
              <label for="color">Cor</label>
            </span>
            <small v-if="errors.has('color')" class="p-error" role="alert">
              Cor é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <Dropdown
                v-model="vehicleModel.office"
                :options="offices"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('office'),
                  },
                  'form-control',
                ]"
                :name="'office'"
                :id="'office'"
              />
              <label for="office">Escritório</label>
            </span>
            <small v-if="errors.has('office')" class="p-error" role="alert">
              Escritório é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <Dropdown
                v-model="vehicleModel.fuel"
                :options="fuelList"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('fuel'),
                  },
                  'form-control',
                ]"
                :name="'fuel'"
                :id="'fuel'"
              />
              <label for="fuel">Combustivel</label>
            </span>
            <small v-if="errors.has('fuel')" class="p-error" role="alert">
              Combustivel é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="vehicleModel.green_way"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('green_way'),
                  },
                  'form-control',
                ]"
                :id="'green_way'"
                :name="'green_way'"
              />
              <label for="green_way">Nº Via Verde</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <InputNumber
                v-model="vehicleModel.kms"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('kms'),
                  },
                  'form-control',
                ]"
                :id="'kms'"
                :name="'kms'"
              />
              <label for="kms">Km's</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <InputNumber
                v-model="vehicleModel.km_next_revision"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('km_next_revision'),
                  },
                  'form-control',
                ]"
                :id="'km_next_revision'"
                :name="'km_next_revision'"
              />
              <label for="km_next_revision">Próxima Revisão (Km's)</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-3">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :name="'inspection_date_picker'"
                  :id="'inspection_date_picker'"
                  v-model="vehicleModel.inspection_date"
                  v-validate="'required'"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'inspection_date'"
                        :id="'inspection_date'"
                        v-bind:class="[
                          {
                            'p-error': errors.has('inspection_date'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        v-validate="'required'"
                      />
                      <label for="inspection_date">Data de Inspeção</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small
              v-if="errors.has('inspection_date')"
              class="p-error"
              role="alert"
            >
              Data de Inspeção é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-3">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="vehicleModel.vin"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('vin'),
                  },
                  'form-control',
                ]"
                :id="'vin'"
                :name="'vin'"
              />
              <label for="vin">VIN</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-3">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="vehicleModel.fleet_card_number"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('fleet_card_number'),
                  },
                  'form-control',
                ]"
                :id="'fleet_card_number'"
                :name="'fleet_card_number'"
              />
              <label for="fleet_card_number">Nº Cartão Frota</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-3">
            <span class="p-float-label">
              <AutoComplete
                :suggestions="filteredEntity"
                @complete="searchEntity($event)"
                v-model="vehicleModel.fleet_card_entity"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('fleet_card_entity'),
                  },
                  'form-control',
                ]"
                :id="'fleet_card_entity'"
                :name="'fleet_card_entity'"
              />
              <label for="fleet_card_entity">Entidade Cartão Frota</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Textarea
                v-model="vehicleModel.comments"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'comments'"
                :name="'comments'"
              />
              <label for="comments">Comentários</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import fleetService from "../../services/fleet.service";
import { getOnlyDate } from "../../helpers/helpers";
export default {
  name: "VehiclesForm",
  props: ["show"],
  components: {},
  data() {
    return {
      loading: true,
      offices: [],
      fuelList: [],
      acquisitionTypesList: [],
      vehicleModel: {},
      brandsList: [],
      filteredBrands: null,
      modelsList: [],
      filteredModels: null,
      colorsList: [],
      filteredColors: null,
      entityList: [],
      filteredEntity: null,
    };
  },
  async created() {
    this.offices = await fleetService.getOffices();
    this.fuelList = await fleetService.getFuel();
    this.acquisitionTypesList = await fleetService.getAcquisitionTypes();
    this.brandsList = await fleetService.getBrands();
    this.modelsList = await fleetService.getModels();
    this.colorsList = await fleetService.getColors();
    this.entityList = await fleetService.getCardsEntity();
    this.loading = false;
  },
  methods: {
    searchBrand(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredBrands = [...this.brandsList];
        } else {
          this.filteredBrands = this.brandsList.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchModels(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredModels = [...this.modelsList];
        } else {
          this.filteredModels = this.modelsList.filter((model) => {
            return model.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchColors(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredColors = [...this.colorsList];
        } else {
          this.filteredColors = this.colorsList.filter((color) => {
            return color.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchEntity(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredEntity = [...this.entityList];
        } else {
          this.filteredEntity = this.entityList.filter((entity) => {
            return entity.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    cancel() {
      this.vehicleModel = {};
      return this.$emit("cancel");
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        if (this.vehicleModel.acquisition_date instanceof Date) {
          this.vehicleModel.acquisition_date = getOnlyDate(
            this.vehicleModel.acquisition_date
          );
        }
        if (this.vehicleModel.registration_date instanceof Date) {
          this.vehicleModel.registration_date = getOnlyDate(
            this.vehicleModel.registration_date
          );
        }
        if (this.vehicleModel.inspection_date instanceof Date) {
          this.vehicleModel.inspection_date = getOnlyDate(
            this.vehicleModel.inspection_date
          );
        }
        if (this.vehicleModel.acquisition_date instanceof Date) {
          this.vehicleModel.acquisition_date = getOnlyDate(
            this.vehicleModel.acquisition_date
          );
        }
        if (this.vehicleModel.registration_date instanceof Date) {
          this.vehicleModel.registration_date = getOnlyDate(
            this.vehicleModel.registration_date
          );
        }
        if (this.vehicleModel.inspection_date instanceof Date) {
          this.vehicleModel.inspection_date = getOnlyDate(
            this.vehicleModel.inspection_date
          );
        }

        fleetService.createVehicle(this.vehicleModel).then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: `Erro ao Adicionar Veículo`,
              detail: "",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: `Veículo Adicionado`,
            detail: "",
            life: 3000,
          });
          if (this.filteredBrands.length == 0) {
            this.brandsList.push(response.brand);
          }
          if (this.filteredModels.length == 0) {
            this.modelsList.push(response.model);
          }
          if (this.filteredColors.length == 0) {
            this.colorsList.push(response.color);
          }
          if (this.filteredEntity.length == 0) {
            this.entityList.push(response.fleet_card_entity);
          }
          this.vehicleModel = {};
          return this.$emit("vehicleCreated", response);
        });
      });
    },
  },
};
</script>
